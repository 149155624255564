import { render, staticRenderFns } from "./SSOSignUp.vue?vue&type=template&id=18c08386&scoped=true"
import script from "./SSOSignUp.vue?vue&type=script&lang=js"
export * from "./SSOSignUp.vue?vue&type=script&lang=js"
import style0 from "./SSOSignUp.vue?vue&type=style&index=0&id=18c08386&prod&lang=scss&scoped=true"
import style1 from "./SSOSignUp.vue?vue&type=style&index=1&id=18c08386&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c08386",
  null
  
)

export default component.exports